import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Blinker from "./blinker.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text sx={{
      fontSize: [2, 2, 2],
      fontWeight: `normal`,
      color: `#00d1b2`
    }} mdxType="Text">
  Hi &#128075; my name is
    </Text>
    <h1>{`Aniket Narayan.`}</h1>
    <h1>{`I build things for the < web `}<Blinker children="/" mdxType="Blinker" />{`>`}</h1>
    <p>{`I'm a full stack software engineer based in New Delhi, India specializing in building, testing, deploying, design and releasing web-apps.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      